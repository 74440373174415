@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    --font-name:'Roboto Mono', monospace;

    font-style: normal;
    font-family: var(--font-name), sans-serif !important;

    --background_color: linear-gradient(0deg, var(--Native-bg_color, #080A23), var(--Native-bg_color, #080A23)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(79, 60, 200, 0.04) 100%);;
    --foreground_color: linear-gradient(180deg, #0D0D29 0%, #14152F 100%);
    --secondary_fill: rgba(79, 60, 200, 0.15);
    --secondary_hint: #4B526A;
    --overlay_color: rgba(255, 255, 255, 0.08);

    --link_color: #4F3CC8;
    --white_color: #FFFFFF;
    --blue_color: #006FEE;
    --yellow_color: var(--blue_color);
    --gray_light_color: rgba(256, 256, 256, .5);
    --gray_color: #2C2C2B;
    --red_color: #ff2b4d;

    scrollbar-width: none;
}

html, body {
    -webkit-user-select: none;
    user-select: none;
}

html {
    line-height: normal;
}

body {
    margin: 0;
    background: var(--background_color);
    color: var(--white_color);

    scroll-behavior: smooth !important;
    overflow: hidden;
    -webkit-overflow-scrolling: touch !important;
    touch-action: none !important;
}

.Base--wrapper {
    background: var(--background_color);
    color: var(--white_color);
}

h1, h2, h3, h4, h5, p, a, span {
    margin: 0;
}

h1 {
    font-size: 32px;
    font-weight: 700;
}

h3 {
    font-size: 20px;
    font-weight: 500;
}

button {
    border: none;
}

.text-14-medium {
    font-weight: 400;
    font-size: 14px;
}

.text-16-medium {
    font-weight: 400;
    font-size: 16px;
}

.align-box-end {
    align-items: end;
}

.text-gray {
    color: var(--gray_light_color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.RainbowOutline {
    background: linear-gradient(rgba(22, 23, 47, 1), rgba(22, 23, 47, 1)) padding-box,
                linear-gradient(110deg, #17AEE9 0%, #E44EBF 60%, #FE7014 100%) border-box;
    border: 2px solid transparent;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 4px solid;
    border-color: white #0000;
    animation: l1 1s infinite;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}